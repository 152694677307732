import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Biostymulatory`}
        description={`Celem zabiegów, które wykorzystują właściwości biostymulatorów, jest odmłodzenie, poprawa sprężystości i wygładzenie skóry twarzy, dekoltu i szyi.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Biostymulatory</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Celem zabiegów, które wykorzystują właściwości biostymulatorów,
                jest odmłodzenie, poprawa sprężystości i wygładzenie skóry
                twarzy, dekoltu i szyi. Rozwój medycyny estetycznej pozwala nam
                wybrać spośród kilku rodzajów biostymulatorów, które różnią się
                zastosowaniem oraz schematem działania. Nasza klinika oferuje
                Państwu bezpieczne dla organizmu biostymulatory, które poprawią
                owal twarzy, odmłodzą czy spłycą zmarszczki.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Na jakie problemy stosujemy stymulatory?
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>w celu likwidacji opadniętych policzków tzw. chomików</li>
                  <li>odbudowy dołów skroniowych</li>
                  <li>uwydatnienia okolicy jarzmowej</li>
                  <li>poprawy jędrności i napięcia</li>
                  <li>intensywnego nawilżenia skóry</li>
                  <li>wysmuklenia i poprawy napięcia twarzy</li>
                  <li>zmniejszenia widoczności zmarszczek</li>
                  <li>
                    usunięcia linii marionetki oraz zmarszczek w okolicach ust
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                W Balicka Clinic stosujemy zabiegi z użyciem
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                aminokwasów, polinukleotydów, ludzkiego kolagenu typu I na bazie
                nici jedwabnych, kolagenu końskiego i świńskiego, kwasu poli
                Del-mlekowego, Hudroxyapatytu wapnia i innych.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
